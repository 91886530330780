import React, { useCallback, useRef } from 'react';
import type { FC } from 'react';
import { useCountUp } from 'react-countup';
import { Text } from 'components';
import { useQuery } from 'styles/breakpoints';

interface CounterProps {
  onEnd: () => void;
  duration?: number;
}

const Counter: FC<CounterProps> = ({ onEnd, duration = 7, ...props }) => {
  const { isMobile } = useQuery();
  const countUpRef = useRef(null);

  const easingFn = useCallback(
    (t: number, b: number, c: number, d: number) =>
      c * ((t = t / d - 1) * t * t * t * t + 1) + b,
    []
  );

  useCountUp({
    ref: countUpRef,
    start: 0,
    end: 100,
    duration,
    suffix: '%',
    easingFn,
    onEnd,
  });

  return (
    <Text type={isMobile ? 'countS' : 'count'} color="dark100" {...props}>
      <span ref={countUpRef} />
    </Text>
  );
};

export default React.memo(Counter);
